<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <!-- Header -->
    <div class="flex justify-between items-center pb-8 border-b border-gray-200">
      <h3 class="text-base md:text-20 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
        Edit post
      </h3>

      <!-- Action buttons -->
      <div class="flex-shrink-0">
        <AppButton
          variant="danger"
          size="standart-sm"
          class="mr-16"
          @click="onDeletePostHandler"
        >
          Delete Post
          <i class="icon-bin text-12 ml-8" />
        </AppButton>
        <AppButton
          :to="{ name: routeNames.posts }"
          variant="danger"
          size="standart-sm"
        >
          Cancel
          <i class="icon-cross text-12 ml-8" />
        </AppButton>
      </div>
    </div>

    <div class="w-full flex pt-16 flex-1 overflow-y-auto">
      <Form
        :validation-schema="postUpdateAndPublishSchema"
        autocomplete="off"
        class="w-full px-4"
        @submit="onSubmitForm"
      >
        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post title</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="title"
                v-model="postModel.title"
                type="text"
                name="title"
                label="Title"
                placeholder="Some text here"
                autofocus
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post thumbnail URL</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="thumbnailUrl"
                v-model="postModel.thumbnailUrl"
                type="text"
                name="thumbnailUrl"
                label="Thumbnail URL"
                placeholder="https://"
                required
              />
            </div>

            <div class="w-full text-center">
              <img
                :src="postModel.thumbnailUrl"
                class="max-h-256 mx-auto"
              >
            </div>

            <AppFileUpload
              id="thumbnailUrl"
              ref="appFileUpload"
              label="Update post thumbnail"
            >
              <template #infoText>
                Please, upload post thumbnail!
              </template>
            </AppFileUpload>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Post content</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="content"
                v-model="postModel.content"
                type="text"
                name="content"
                label="Content"
                placeholder="Some text"
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full">
          <p class="font-medium text-left mb-16 text-blue-600 whitespace-normal">Social media content</p>
          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="telegram"
                v-model="postModel.telegram"
                type="text"
                name="telegram"
                label="Telegram content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="twitter"
                v-model="postModel.twitter"
                type="text"
                name="twitter"
                label="Twitter content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>

          <div class="w-full flex flex-wrap">
            <div class="w-full">
              <AppInput
                id="instagram"
                v-model="postModel.instagram"
                type="text"
                name="instagram"
                label="Instagram content"
                placeholder="Some text here..."
                required
                textarea
              />
            </div>
          </div>
        </div>

        <div class="w-full flex justify-center pt-24 pb-24 md:pb-40 md:pt-40">
          <div class="w-full md:w-1/2">
            <AppButton
              :loading="loading"
              variant="primary"
              type="submit"
              class="w-full"
            >
              Update and Publish
            </AppButton>
          </div>
        </div>
      </Form>
    </div>

    <AppConfirmModal
      v-model="deleteModalOpened"
      @onConfirmClick="onDeleteConfirmHandler"
      @onCancelClick="onDeleteCancelHandler"
    >
      <template #modal-title>
        Are you sure you want to delete post?
      </template>
    </AppConfirmModal>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Form } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'

import { notification } from '@/core/helpers'
import { routeNames } from '@/router'
import { postUpdateAndPublishSchema } from '@/core/schemas'
import { usePosts, useSocialSignatures } from '@/core/compositions'

import AppButton from '@/components/stateless/AppButton.vue'
import AppInput from '@/components/stateless/AppInput.vue'
import AppConfirmModal from '@/components/stateless/modals/AppConfirmModal.vue'
import AppFileUpload from '@/components/stateless/AppFileUpload.vue'

export default defineComponent({
  name: 'EditResource',

  components: {
    Form,
    AppButton,
    AppInput,
    AppConfirmModal,
    AppFileUpload
  },

  setup () {
    const router = useRouter()
    const route = useRoute()

    const { postModel, getPost, editPost, deletePost, publishPost } = usePosts()
    const { socialSignatures, getSocialSignatures } = useSocialSignatures()

    const loading = ref(true)
    const deleteModalOpened = ref(false)
    const appFileUpload = ref(null)

    const postId = computed(() => {
      return route.params?.id
    })

    onMounted(() => {
      getInitialData()
    })

    const wireSocialSignaturesWithPostLabels = () => {
      socialSignatures.value.forEach(s => {
        const title = s.platform === 'telegram'
          ? ('<b>' + postModel.title + '</b>')
          : postModel.title

        postModel[s.platform] = title + (s.signature ? ('\n\n' + s.signature) : '')
      })
    }

    const getInitialData = async () => {
      loading.value = true

      try {
        await Promise.all([
          getPost(postId.value),
          getSocialSignatures({ page: 1, size: 100 })
        ])

        wireSocialSignaturesWithPostLabels()
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onSubmitForm = async () => {
      loading.value = true

      try {
        const isThumbnailAttached = appFileUpload.value.isFileSelected
        if (isThumbnailAttached) {
          const res = await appFileUpload.value.submitUpload()
          if (res?.url) {
            postModel.thumbnailUrl = res.url
          }
        }

        await editPost(postId.value)

        await publishPost(postId.value)

        notification({ type: 'success', title: 'Success!', message: 'Post successfully updated and published!' })

        router.push({ name: routeNames.posts })
      } catch (err) {
        console.log(err)
        notification({ title: err.error.message, errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    const onDeletePostHandler = async () => {
      deleteModalOpened.value = true
    }

    const onDeleteConfirmHandler = async () => {
      loading.value = true

      try {
        await deletePost(postId.value)

        notification({ type: 'success', title: 'Success!', message: 'Post successfully deleted!' })

        router.push({ name: routeNames.posts })
      } catch (err) {
        console.log(err)
        notification({ title: 'Post deleting error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }
    const onDeleteCancelHandler = async () => {
      deleteModalOpened.value = false
    }

    return {
      routeNames,
      postUpdateAndPublishSchema,
      loading,
      onSubmitForm,
      postModel,
      appFileUpload,

      onDeletePostHandler,
      deleteModalOpened,
      onDeleteConfirmHandler,
      onDeleteCancelHandler
    }
  }
})
</script>
